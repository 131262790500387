<template>
  <div class="SetLine">
    <el-button @click="back"
               class="backbtn">返回</el-button>
    <el-button @click="comfire"
               class="comfirebtn">确定</el-button>
    <span class="text-black"
          style="margin-left:10px;">材料选择</span>
    <el-table :data="tablelist"
              header-row-class-name="header-row"
              style="width: 100%;margin-top:30px;"
              max-height="600"
              ref="mtable"
              @selection-change="handleSelectionChange">
      <el-table-column type="selection"
                       width="55" />
      <el-table-column prop="formName"
                       label="材料名称" />
      <el-table-column
                       label="类型">
        <template #default="scope">
          {{itemTypeList[scope.row.formType]||scope.row.formType}}
        </template>
      </el-table-column>
      <el-table-column prop="uploadRule"
                       label="上传规则" />
    </el-table>
    <!-- <div v-else
         class="noconfig-context">
      <img class="noconfig-img"
           :src="require(`@/assets/images/configListEdit/noConfig.png`)">
      <span class="noconfig-text">暂无材料，请添加</span>
      <el-button class="noconfig-btn">添加材料</el-button>
    </div> -->
    <div>
    </div>
  </div>
</template>

<script>
import { IconFont } from '@/utils/iconfont'
import lineCard from './lineComponent/lineCard.vue' //分页item
import linePopup from './lineComponent/linePopup.vue' //新增弹框
import { queryFormListByLineId, updateLineForm} from '@/api/config/config'
export default {
  name: 'SetLine',
  components: {
    IconFont,
    lineCard,
    linePopup,
  },
  data() {
    return {
      searchForm: {
        page: 1,
        pageSize: 52,
        mode: '0',
        sortBy: ['createTime'],
        sortDesc: ['false'],
      },
      tablelist: [
        {
          name: 'NAME',
          type: 'TYPE',
          rule: 'RULE',
        },
      ],
      onSelectConfig: [],
      itemTypeList: {
        img: '图片',
        video: '视频',
        textarea: '文本域',
        switch: '滑块',
        input: '输入框',
        radio: '单选',
        checkbox: '多选',
        select: '下拉选择',
        upload: '上传',
        date: '日期',
        cascader: '级联',
        mobileTextarea:'电话文本域',
        word:'文档',
        excel:'表格',
        industry:'所属行业'
      },
      lineId:-1
    }
  },
  mounted() {
    // 监听滚动事件
    // this.divScrollChange()
    // for (let i = 0; i < 100; i++) {
    //   this.tablelist.push({
    //     id: i,
    //     name: 'NAME'+i,
    //     type: 'TYPE',
    //     rule: 'RULE',
    //   })
    // }
    // let list = JSON.parse(localStorage['formlist']||'[]')
    // this.tablelist = list.map(e=>{
    //   return JSON.parse(localStorage[e]);
    // });
    // let onselect = JSON.parse(localStorage['onselectForm']);
    // this.tablelist.forEach(e=>{
    //   if(~onselect.findIndex(item=>item == escape(e.formName))){
    //     this.$refs.mtable.toggleRowSelection(e,true);
    //   }
    // })
    // this.$refs.mtable.
    this.lineId = this.$route.query.id||-1
    this.getDataList()
  },
  methods: {
    handleSelectionChange(selection) {
      console.log({ selection })
      this.onSelectConfig = selection
    },
    comfire() {
      // localStorage['onselectForm'] = JSON.stringify(this.onSelectConfig.map(e=>escape(e.formName)))
      const loading = this.$loading();
      let lineForms = this.onSelectConfig.map(e=>{
        let {active,formContext,formName,formType,required,reuse,uploadRule} = e;
        // formContext = JSON.parse(formContext);
        return {active,formContext,formName,formType,required,reuse,uploadRule,description:'',exampleUrl:'',modelUrl:'',choose:0}
      })
      updateLineForm({lineId:this.lineId,lineForms}).then(res=>{
        loading.close()
        this.getDataList()
        this.$notify.success({
          message:'更新成功'
        })
      }).catch(err=>{
        loading.close()
      })
    },
    getDataList() {
      const loading = this.$loading({
        title:'LOADING'
      })
      console.log('dodododododo');
      queryFormListByLineId(this.lineId).then(res=>{
        loading.close();
        this.tablelist = res.data;
        this.$nextTick(e=>{
          this.tablelist.forEach(e=>{
            if(!e.choose){
              this.$refs['mtable'].toggleRowSelection(e,true);
            }
          })
        })
      }).catch(err=>{
        loading.close()
      })
    },
    back() {
      this.$router.go(-1)
    },
  },
}
</script>
<style lang="scss" scoped>
.comfirebtn {
  float: right;
  margin-right: 10px;
  width: 80px;
  height: 32px;
  background: #637dff;
  border-radius: 4px;
  font-size: 12px;
  font-family: PingFang SC, PingFang SC-Regular;
  font-weight: 400;
  text-align: left;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
}
.backbtn {
  float: right;
  width: 80px;
  height: 32px;
  background: #ffffff;
  border: 1px solid #c7c7c7;
  border-radius: 4px;
  font-size: 12px;
  font-family: PingFang SC, PingFang SC-Regular;
  font-weight: 400;
  text-align: left;
  color: #999999;
  display: flex;
  justify-content: center;
  align-items: center;
}
.SetLine {
  padding-right: 1.125rem;
}
.lineCard-show-box {
  padding-top: 20px;
  height: calc(100vh - 250px);
  overflow-y: overlay;
}
.type-name {
  width: 80px;
}
.line-in-button {
  width: 100%;
}
.button-left {
  width: 100%;
  color: #333333;
  background: #f2f3f8;
}
.button-right {
  width: 100%;
  color: #ffffff;
  background: #637dff;
}
.font-refresh-16 {
  font-size: 14px;
  color: #637dff;
}
.df-item {
  font-size: 14px;
  font-family: PingFang SC, PingFang SC-Regular;
  font-weight: 400;
  text-align: left;
  color: #09132d;
  letter-spacing: 0px;
  margin: 0 20px;
  cursor: pointer;
  &.active {
    color: #637dff;
  }
}
.flex {
  display: flex;
  &.center {
    align-items: center;
  }
  &.mt {
    margin-top: 10px;
  }
}
.text-gray {
  color: #86909c;
}
.text-black {
  color: #000;
}
.configtext {
  font-size: 14px;
  font-family: PingFang SC, PingFang SC-Bold;
  font-weight: 700;
  text-align: left;
  color: #09132d;
}
.append-margin {
  margin-top: 15px;
}
::v-deep(.header-row th) {
  background: #eeeef3;
}
.button-text-edit {
  color: #637dff;
}
.button-text-del {
  color: #ff5757;
}
.noconfig-context {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .noconfig-img {
    width: 113px;
    margin-top: 180px;
  }
  .noconfig-text {
    font-size: 14px;
    font-family: PingFang SC, PingFang SC-Regular;
    font-weight: 400;
    text-align: left;
    color: #86909c;
    margin-top: 28px;
  }
  .noconfig-btn {
    width: 108px;
    height: 32px;
    background: #637dff;
    border-radius: 4px;
    font-size: 14px;
    font-family: PingFang SC, PingFang SC-Regular;
    font-weight: 400;
    text-align: left;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 34px;
  }
}
</style>